import { NavLink } from "react-router-dom";
import styled from "styled-components";
import FadeInEffect from "./FadeInEffect";

const ProjectsList = ({ projectsList }) => {
  return (
    <Main className="grid grid-cols-2 gap-8">
      {projectsList.map((item, index) => {
        return (
          <Item key={item.id}>
            <FadeInEffect>
              <ImageWrapper key={`${item}-${index}-${Date.now()}`}>
                <Image
                  // ref={imageRefs.current[index]}
                  // data-index={index}
                  src={item.coverImage}
                  // isVisible={isVisibleArray[index]}
                />
              </ImageWrapper>
            </FadeInEffect>

            <H1 className="font-bold text-black pt-3 text-[24px]">
              {item.name}
            </H1>
            <H2 className="text-[22px] leading-[30px] py-[10px]">
              {item.about}
            </H2>
            <NavLink to={`/projects/${item.id}`}>
              <ViewH2 className="text-[20px]  font-bold">
                VIEW PROJECT {">"}{" "}
              </ViewH2>
            </NavLink>
          </Item>
        );
      })}
    </Main>
  );
};

const Main = styled.div`
  @media only screen and (max-width: 540px) {
    display: flex;
    flex-direction: column;
  }
  padding-bottom: 60px;
`;
const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 82%;
  height: 0;
`;

// const fadeIn = keyframes`
//   0% {
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// `;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;

const Item = styled.div``;

const H1 = styled.h1`
  @media only screen and (max-width: 440px) {
    font-size: 22px;
  }
`;
const H2 = styled.h2`
  @media only screen and (max-width: 1024px) {
    font-size: 22px;
    line-height: 30px;
    padding: 10px 0;
  }
`;

const ViewH2 = styled.h2`
  &:hover {
    color: black;
  }
  @media only screen and (max-width: 620px) {
    font-size: 18px;
  }
`;
export default ProjectsList;
