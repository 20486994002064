import { useState } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { projects } from "./data";

const ProjectCarousel = ({ currentProjectId }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    dots: true,

    beforeChange: (current, next) => setCurrentSlide(next),

    responsive: [
      {
        breakpoint: 640, // width at which to apply these settings
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const projectsArr = Object.values(projects);
  // Get the index of the current project
  const currentProjectIndex = projectsArr.findIndex(
    (project) => project.id === currentProjectId
  );

  // Filter out the current project from the array of projects
  const otherProjectsArr = projectsArr.filter(
    (project, index) => index !== currentProjectIndex
  );

  return (
    <CarouselWrapper>
      <h2>Other Projects</h2>
      <StyledSlider {...settings}>
        {otherProjectsArr.map((project, index) => (
          <ProjectCard
            key={index}
            className={currentSlide === index ? "active" : ""}
          >
            <h3>{project.name}</h3>
            <ImageWrapper>
              <NavLink to={`/projects/${project.id}`}>
                <ProjectImage src={project.imgUrl[0]} alt={project.name} />
              </NavLink>
            </ImageWrapper>
          </ProjectCard>
        ))}
      </StyledSlider>
    </CarouselWrapper>
  );
};

const CarouselWrapper = styled.div`
  margin: 50px auto;
  width: 80%;
  max-width: 1000px;
  h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
`;

const ProjectCard = styled.div`
  /* height: 300px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 20px;
  h3 {
    margin-bottom: 20px;
  }
`;

const ProjectImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 350px;
  height: auto;
  cursor: pointer;
  object-fit: scale-down;
  @media only screen and (max-width: 1200px) {
    max-width: 300px;
  }
  @media only screen and (max-width: 875px) {
    max-width: 250px;
  }
  @media only screen and (max-width: 750px) {
    max-width: 200px;
  }
  @media only screen and (max-width: 380px) {
    max-width: 150px;
  }
`;
const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 82%;
  height: 0;
`;
const Arrow = styled.button`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  &:hover {
    opacity: 0.7;
  }
`;

const PrevArrow = styled(Arrow)`
  left: -40px;
  color: green;
  background-color: blue;
  z-index: 10;
`;

const NextArrow = styled(Arrow)`
  right: -40px;
  color: green;
  background-color: blue;
  z-index: 10;
`;

const StyledSlider = styled(Slider)`
  .slick-list {
    /* overflow: visible; */
  }
  .slick-slide > div {
    margin: 0 15px;
  }
  .slick-arrow {
    z-index: 10;
  }
  .slick-prev,
  .slick-next {
    z-index: 10;
    &:before {
      /* font-size: 30px; */
      color: #626262;
    }
  }

  .slick-prev {
    left: -40px;
  }

  .slick-next {
    right: -40px;
  }
`;

export default ProjectCarousel;
