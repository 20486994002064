import { useParams, NavLink } from "react-router-dom";
import styled from "styled-components";
import FadeInEffect from "./FadeInEffect";
import { codeZone } from "./data";

const CodeDetail = () => {
  const { itemId } = useParams();
  const item = codeZone[itemId];

  const generateRows = () => {
    return item.highlights.codeHl.map((imgUrl, index) => (
      <Row key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
        {index % 2 === 0 ? (
          <>
            <ImageColumn>
              <ImageWrapper>
                <Image src={imgUrl} />
              </ImageWrapper>
            </ImageColumn>
            <TextColumn>
              <Highlight>
                <H2>{item.highlights.titleHl[index]}</H2>
                {item.highlights.textHl[index]}
              </Highlight>
            </TextColumn>
          </>
        ) : (
          <>
            <TextColumn>
              <Highlight>
                <H2>{item.highlights.titleHl[index]}</H2>
                {item.highlights.textHl[index]}
              </Highlight>
            </TextColumn>
            <ImageColumn>
              <ImageWrapper>
                <Image src={imgUrl} />
              </ImageWrapper>
            </ImageColumn>
          </>
        )}
      </Row>
    ));
  };

  return (
    <Main>
      <FadeInEffect>
        <h1 className="text-[30px] font-bold leading-[40px] py-[30px]">
          {item.name}
        </h1>
      </FadeInEffect>
      <FadeInEffect>
        <Description>{item.description}</Description>
      </FadeInEffect>
      <FadeInEffect>
        <table className="table-auto my-[30px] text-[22px]">
          <thead className="text-left">
            <tr>
              <th className="pb-[20px] lg:pr-[200px]">Language</th>
              <th className="pb-[20px] lg:pr-[200px]">Code</th>
              {/* <th className="pb-[20px] lg:pr-[200px]">Practices</th> */}
            </tr>
          </thead>
          <tbody>
            {item.language.map((element, index) => (
              <tr key={index}>
                <td>{element}</td>
                {index === 0 && (
                  <>
                    <td>
                      <StyledNavLink
                        to={{ pathname: item.code }}
                        target="_blank"
                        style={{ fontSize: "22px", paddingRight: "13px" }}
                      >
                        Repository
                      </StyledNavLink>
                    </td>
                    <td>
                      {/* {item.features.map((feature, featureIndex) => (
                        <div key={featureIndex}>{feature}</div>
                      ))} */}
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </FadeInEffect>
      <ContentGrid>{generateRows()}</ContentGrid>
    </Main>
  );
};

const Main = styled.div`
  margin: 80px 100px 0px 100px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    margin: 80px 60px 0px 60px;
    padding: 0;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  padding: 40px 0;
  grid-gap: 40px 0;

  .even-row {
    grid-template-columns: 2fr 1fr;
  }

  .odd-row {
    grid-template-columns: 1fr 2fr;
  }

  @media only screen and (max-width: 1024px) {
    .even-row,
    .odd-row {
      grid-template-columns: 1fr !important;
      flex-direction: column-reverse;
    }
  }
`;

const Row = styled.div`
  display: grid;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    order: 2;
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 1024px) {
    order: 1;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 56%;
  height: 0;
  margin-bottom: 20px;
`;

const Image = styled.img`
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //   object-fit: scale-down;
  &:hover {
    transform: scale(1.2);
  }
  transition: transform 0.6s ease, filter 0.6s ease;
`;

const Highlight = styled.div`
  padding: 20px;
  margin: 0 40px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 1024px) {
    margin: 20px 0;
  }
`;

const H2 = styled.h2`
  padding: 20px 0;
  font-weight: bolder;
  font-size: 20px;
`;

const Description = styled.div`
  color: grey;
  font-size: 22px;
  line-height: 30px;
  @media only screen and (max-width: 1024px) {
  }
  @media only screen and (max-width: 440px) {
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #ea7a20;
  &:hover {
    font-weight: bold;
  }
`;

export default CodeDetail;
