import { Link, NavLink } from "react-router-dom";

const ButtonMailto = ({ mailto, label }) => {
  return (
    <NavLink
      to="#"
      style={{ fontSize: "14px", fontWeight: "bold", padding: "5px" }}
      onClick={(e) => {
        window.location.href = mailto;
        e.preventDefault();
      }}
    >
      {label}
    </NavLink>
  );
};

export default ButtonMailto;
