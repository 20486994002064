export const projects = {
  auto_explorer: {
    id: "auto_explorer",
    name: "Auto-Explorer",
    coverImage: "/images/auto-explorer/home.png",
    imgUrl: [
      // "/images/auto-explorer/home.jpg",
      "/images/auto-explorer/home.png",
      "/images/auto-explorer/postad.png",
      "/images/auto-explorer/dashboard.png",
      // "/images/auto-explorer/myAccount.jpg",
      // "/images/auto-explorer/myAdMessages.jpg",
      "/images/auto-explorer/details.png",
      "/images/auto-explorer/messages.png",
    ],
    about:
      "A car-listing web app created using MERN Stack to facilitate cars buying/selling process",
    stack: ["React.js", "Styled-Components", "NodeJS", "MongoDB"],
    live: "https://auto-explorer.herokuapp.com",
    code: "https://github.com/MohsenBahaeddini/final-project",
    description:
      "Auto-Explorer is a fully responsive Car-Listing web app which is developed by Mohsen Bahaeddini as the final project of Concordia Full-Stack Web Development Bootcamps. Auto-Explorer facilitates the cars Buying/Selling process by allowing users to post their cars ad or find their future cars by just simply searching among listed cars.",
  },
  bc_imb: {
    id: "bc_imb",
    name: "BC Web App Manager",
    coverImage: "/images/bc_imb/newproduct.png",
    imgUrl: [
      "/images/bc_imb/newproduct.png",
      "/images/bc_imb/home.png",
      "/images/bc_imb/developer.png",
      "/images/bc_imb/scrum.png",
    ],
    about:
      "A web app for managing and cataloging modern applications, supporting product tracking and CRUD operations.",
    stack: ["React.js", "Styled-Components", "NodeJS", "Express"],
    live: "https://bc-imb.herokuapp.com/",
    code: "https://github.com/MohsenBahaeddini/MOHSEN-BAHAEDDINI-IS24-full-stack-competition-req97073",
    description:
      "The BC Web Application Tracker streamlines web app management and cataloging. Offering comprehensive tracking for product details, development phases, and team roles, this platform leverages React.js, Node.js, and Express to simplify CRUD operations. It combines advanced technology with user-friendly design, ensuring efficient and intuitive product management.",
  },
  // face_space: {
  //   id: "face_space",
  //   name: "Face Space",
  //   imgUrl: ["/images/face-space/home-links.gif"],
  //   about: "A social media platform to connect with friends and family",
  //   stack: ["React.js", "NodeJS", "CSS", "Styled-Components"],
  //   live: "https://facespace.herokuapp.com/",
  //   code: "https://github.com/MohsenBahaeddini/MOHSEN-BAHAEDDINI-IS24-full-stack-competition-req97073",
  //   description:
  //     "Face Space is a social media platform built using the latest technologies such as React, Node, and CSS. With Face Space, you can easily connect with friends and family, and explore a vast network of like-minded individuals from around the world.",
  // },
  // sling_air: {
  //   id: "sling_air",
  //   name: "Slingshot Airlines",
  //   coverImage: "/images/sling-air/slingair-mvp.gif",
  //   imgUrl: ["/images/sling-air/slingair-mvp.gif"],
  //   about: "A flight-booking app built using React & Node.JS",
  //   stack: ["React.js", "Styled-Components", "NodeJS"],
  //   live: "https://sling-air.herokuapp.com/",
  //   code: "https://github.com/MohsenBahaeddini/slingair",
  //   description:
  //     "Discover the ease of booking your next flight with Sling Air reservation app. Built with React, Node.js and styled components, this app provides a  allows users to choose a flight, book a seat and get ready for their travel with just a few clicks.",
  // },
  // dressr: {
  //   id: "dressr",
  //   name: "Dressr",
  //   imgUrl: [
  //     "/images/dressr/cart.jpg",
  //     "/images/dressr/home.jpg",
  //     "/images/dressr/item.jpg",
  //     "/images/dressr/confirmation.jpg",
  //   ],
  //   about: "An e-commerce web app built with React and a RESTful Node server",
  //   stack: ["React.js", "Styled-Components", "NodeJS"],
  //   live: "https://dressr.herokuapp.com/",
  //   code: "https://github.com/MohsenBahaeddini/ecommerce-group-project",
  //   description:
  //     "Dressr is an e-commerce web app that was developed by a team of three junior developers as the group project of Concordia Full-Stack Web Dev Bootcamps. Users are able to search among products, filter their searches by manufacturer, modify their cart items and finally complete their orders.",
  // },
  crsmgr: {
    id: "crsmgr",
    name: "CrsMgr+",
    coverImage: "/images/crsmgr/crsmgrHome.png",
    imgUrl: [
      "/images/crsmgr/crsmgrHome.png",
      "/images/crsmgr/communication.png",
      "/images/crsmgr/files.png",
      // "/images/crsmgr/student.png",
      // "/images/dressr/item.jpg",
      // "/images/dressr/confirmation.jpg",
    ],
    about: "A comprehensive course management web app built with JS and PHP",
    stack: ["JavaScript", "PHP", "HTML", "CSS"],
    // live: "https://dressr.herokuapp.com/",
    code: "https://github.com/MohsenBahaeddini/CrsMgrPlus-COMP5531",
    description:
      "CrsMgr+ is an advanced web application designed to streamline course administration, enhance group project collaboration, and facilitate seamless communication among instructors, teaching assistants, and students. Built with a robust suite of tools, CrsMgr+ serves as a comprehensive platform for managing educational resources efficiently and effectively.",
  },
};

// CODE ZONE
export const codeZone = {
  vocabManager: {
    id: "vocabManager",
    name: "WordWizard",
    coverImage: "/images/word_wizard/wordWizard.png",
    highlights: {
      codeHl: [
        "/images/word_wizard/hl1.png",
        "/images/word_wizard/hl2.png",
        "/images/word_wizard/hl3.png",
      ],
      titleHl: [
        "Dynamic Array Resizing",
        "Doubly Linked List Implementation",
        "Pointer Efficiency",
      ],
      textHl: [
        "Dynamically resizing arrays when full and reducing size when less than half full to ensure optimal memory usage.",
        "Managing nodes with a doubly linked list for efficient insertion, deletion, and traversal, ensuring operations are performed in constant or logarithmic time.",
        "Using pointers to avoid unnecessary copies, enhancing efficiency in dynamic memory management.",
      ],
    },
    // imgUrl: [
    //   // "/images/auto-explorer/home.jpg",
    //   "/images/word_wizard/wordWizard.png",
    //   "/images/auto-explorer/postad.png",
    //   "/images/auto-explorer/dashboard.png",
    //   // "/images/auto-explorer/myAccount.jpg",
    //   // "/images/auto-explorer/myAdMessages.jpg",
    //   "/images/auto-explorer/details.png",
    //   "/images/auto-explorer/messages.png",
    // ],
    about:
      "Vocabulary Manager: Enhancing Learning Through Organized Word Lists",
    language: ["C++"],
    code: "https://github.com/MohsenBahaeddini/WordWizard",
    features: [
      "Dynamic Category Management",
      "Interactive Word Handling",
      // "User-Friendly Interface",
      // "Robust Backend",
    ],

    description:
      "A project to create a robust, modular, and efficient system for managing and manipulating dynamically allocated words, categorized into different word categories. The project focuses on practicing manual memory management techniques, copy semantics, and move semantics in C++.",
    challenge:
      "Reinventing the wheel! The most significant challenge was managing dynamic memory effectively and ensuring efficient performance without relying on modern C++11 features. This required a deep understanding of manual memory management, copy semantics, and move semantics.",
  },
  nfaDfaConverter: {
    id: "nfaDfaConverter",
    name: "NFA to DFA Converter",
    coverImage: "/images/nfaToDfaConvertor/coverImage.png",
    highlights: {
      codeHl: [
        "/images/nfaToDfaConvertor/hl1.png",
        "/images/nfaToDfaConvertor/hl2.png",
        "/images/nfaToDfaConvertor/coverImage.png",
      ],
      titleHl: [
        "Generating the Power Set",
        "Building the DFA Transition Table",
        "Final DFA Diagram",
      ],
      textHl: [
        "This helper function generates the power set of a given set s. The power set is the set of all subsets of s, including the empty set and s itself. This is crucial for creating all possible states of the DFA, as each state in the DFA corresponds to a subset of states in the NFA.",
        "For each state in the DFA (which corresponds to a subset of NFA states), it calculates the closure for each possible input ('0' and '1'). The closure is the set of states reachable from the current state on the given input. By using frozenset, we ensure that each state is hashable and can be used as a key in the dictionary.",
        "The resulting DFA diagram represents the deterministic finite automaton derived from the given NFA. Each state in the DFA is a set of NFA states, and transitions are clearly defined for each input symbol, ensuring deterministic behavior. The double-circled state {q0,q2} is the accepting state, meaning any input string ending in this state is accepted by the automaton. The initial state is {𝑞0}, from which processing begins. This deterministic structure simplifies input processing, making the DFA suitable for applications requiring precise and predictable behavior, such as pattern recognition and text processing.",
      ],
    },
    // imgUrl: [
    //   // "/images/auto-explorer/home.jpg",
    //   "/images/auto-explorer/home.png",
    //   "/images/auto-explorer/postad.png",
    //   "/images/auto-explorer/dashboard.png",
    //   // "/images/auto-explorer/myAccount.jpg",
    //   // "/images/auto-explorer/myAdMessages.jpg",
    //   "/images/auto-explorer/details.png",
    //   "/images/auto-explorer/messages.png",
    // ],
    about: "Converting NFA to DFA for Efficient Computation",
    language: ["Python"],
    code: "https://github.com/MohsenBahaeddini/FiniteAutomata",
    features: [],

    description:
      "Converting a Nondeterministic Finite Automaton (NFA) into its equivalent Deterministic Finite Automaton (DFA) is a fundamental task in automata theory, essential for applications in pattern recognition, compiler design, and text processing where deterministic behavior is critical. An NFA allows multiple possible transitions for a given state and input, making it flexible but complex, as it can be in multiple states simultaneously. For example, an NFA recognizing the pattern '01' might transition to several states after reading '0', complicating its state management. In contrast, a DFA has exactly one transition per state and input pair, which simplifies execution and ensures predictable behavior. This project involved implementing this conversion, addressing the challenge of efficiently mapping NFA states to DFA states and visualizing the resulting DFA. This conversion not only makes the automaton's behavior easier to understand and use but also ensures accurate and reliable performance in practical applications.",
    challenge:
      "Handling the state explosion problem was the most significant challenge in converting the NFA to a DFA. This required efficiently managing the potentially exponential increase in the number of states, ensuring that the conversion process was both accurate and performant. Additionally, visualizing the DFA while maintaining clarity and usability posed an added complexity, demanding careful implementation of state transition mappings and rendering techniques.",
  },
  // vocabManager3: {
  //   id: "vocabManager3",
  //   name: "WordWizard1",
  //   highlights: {
  //     codeHl: [],
  //     textHl: [],
  //   },
  //   imgUrl: [
  //     // "/images/auto-explorer/home.jpg",
  //     "/images/auto-explorer/home.png",
  //     "/images/auto-explorer/postad.png",
  //     "/images/auto-explorer/dashboard.png",
  //     // "/images/auto-explorer/myAccount.jpg",
  //     // "/images/auto-explorer/myAdMessages.jpg",
  //     "/images/auto-explorer/details.png",
  //     "/images/auto-explorer/messages.png",
  //   ],
  //   about:
  //     "Vocabulary Manager: Enhancing Learning Through Organized Word Lists",
  //   language: ["C++"],
  //   // live: "https://auto-explorer.herokuapp.com",
  //   features: [
  //     "Dynamic Category Management",
  //     "Interactive Word Handling",
  //     "User-Friendly Interface",
  //     "Robust Backend",
  //   ],
  //   coverImage: "/images/word_wizard/wordWizard.png",
  //   description:
  //     "Dive into the world of efficient vocabulary management with our interactive C++ project, designed to assist educators in compiling, categorizing, and managing word lists effortlessly. Built to cater to the dynamic needs of students and teachers, this project emphasizes user-friendly interactions and seamless word categorization.",
  // },
};



