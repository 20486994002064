// import { useParams } from "react-router-dom";
// import styled from "styled-components";
// import { NavLink } from "react-router-dom";
// import FadeInEffect from "./FadeInEffect";
// import { projects } from "./data";
// import ProjectCarousel from "./ProjectCarousel";

// const ProjectDetail = () => {
//   const { itemId } = useParams();

//   const item = projects[itemId];

//   return (
//     <Main>
//       <FadeInEffect>
//         <h1 className="text-[30px] font-bold leading-[40px] py-[30px]">
//           {item.name}
//         </h1>
//       </FadeInEffect>
//       <FadeInEffect>
//         <Description>{item.description}</Description>
//       </FadeInEffect>
//       <FadeInEffect>
//         <table className="table-auto my-[30px] text-[22px] ">
//           <thead className="text-left  ">
//             <tr>
//               <th className="pb-[20px] lg:pr-[200px]">Stack</th>
//               <th className="pb-[20px] lg:pr-[200px]">Code</th>
//               {item.live ? (
//                 <th className="pb-[20px] lg:pr-[200px]">Live</th>
//               ) : (
//                 <></>
//               )}
//             </tr>
//           </thead>
//           <tbody>
//             {item.stack.map((element, index) => {
//               return (
//                 <tr key={Math.random()}>
//                   <td>{element}</td>
//                   {index === 0 ? (
//                     <>
//                       <td>
//                         <StyledNavLink
//                           to={{ pathname: item.code }}
//                           target="_blank"
//                           style={{ fontSize: "22px", paddingRight: "13px" }}
//                         >
//                           Repositery
//                         </StyledNavLink>
//                       </td>
//                       {item.live ? (
//                         <td>
//                           <StyledNavLink
//                             to={{ pathname: item.live }}
//                             target="_blank"
//                             style={{ fontSize: "22px" }}
//                           >
//                             Website
//                           </StyledNavLink>
//                         </td>
//                       ) : (
//                         <></>
//                       )}
//                     </>
//                   ) : (
//                     <td />
//                   )}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </FadeInEffect>
//       <FadeInEffect>
//         <ImgDiv>
//           {item.imgUrl.map((element, index) => {
//             return (
//               <ImageWrapper key={index}>
//                 <Image src={element} />
//               </ImageWrapper>
//             );
//           })}
//         </ImgDiv>
//       </FadeInEffect>
//       <ProjectCarousel currentProjectId={itemId} />
//     </Main>
//   );
// };
// const ImageWrapper = styled.div`
//   position: relative;
//   padding-bottom: 56%;
//   height: 0;
//   margin-bottom: 20px;
// `;

// const Image = styled.img`
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: scale-down;
// `;
// const SBSImageWrapper = styled.div`
//   /* display: flex;
//   justify-content: space-between;
//   width: 100%;
//   padding-bottom: 50%;
//   height: 0;
//   overflow: hidden; */
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   width: 100%;
//   margin-bottom: 20px;
// `;
// const SBSImage = styled.img`
//   /* width: 48%;
//   height: 100%;
//   object-fit: cover; */
//   padding: 0 20px;
//   width: 35%;
//   height: 100%;
// `;
// const Main = styled.div`
//   margin: 80px 100px 0px 100px;
//   padding: 0 50px;
//   display: flex;
//   flex-direction: column;
//   @media only screen and (max-width: 1024px) {
//     margin: 80px 60px 0px 60px;
//     padding: 0;
//   }
// `;
// const DImg1 = styled.div`
//   @media only screen and (max-width: 780px) {
//     // margin-right: 150px;
//   }
// `;
// const DImg2 = styled.div`
//   display: flex;
//   justify-content: center;
//   @media only screen and (max-width: 780px) {
//     // margin-right: 150px;
//   }
// `;
// const DImg3 = styled.div`
//   display: flex;
//   justify-content: center;
//   @media only screen and (max-width: 780px) {
//     // margin-right: 150px;
//   }
// `;

// const DImg4 = styled.div`
//   display: flex;
//   justify-content: center;
//   @media only screen and (max-width: 780px) {
//     // margin-right: 150px;
//   }
// `;

// const Description = styled.div`
//   color: grey;
//   font-size: 22px;
//   line-height: 30px;
//   @media only screen and (max-width: 768px) {
//     // margin-right: -100px;
//   }
//   @media only screen and (max-width: 440px) {
//     // margin-right: -300px;
//   }
// `;
// const Tbody = styled.tbody`
//   color: grey;
// `;
// const Table = styled.table``;
// const ImgDiv = styled.div`
//   display: flex;
//   flex-direction: column;
//   margin: 50px 0 100px;
//   background: aliceblue;
//   border-radius: 10px;
//   box-shadow: 0 0 90px 90px aliceblue;
//   @media only screen and (max-width: 780px) {
//     // margin-right: -100px;
//     // margin-left: 100px;
//   }
//   @media only screen and (max-width: 610px) {
//     // margin-right: -250px;
//     // margin-left: 100px;
//   }
//   @media only screen and (max-width: 510px) {
//     // margin-right: -350px;
//     // margin-left: 100px;
//   }
//   @media only screen and (max-width: 400px) {
//     // margin-right: -450px;
//     // margin-left: 100px;
//   }
// `;
// const SideImg = styled.img`
//   @media only screen and (max-width: 840px) {
//     width: 250px;
//     height: 500px;
//   }
//   @media only screen and (max-width: 600px) {
//     width: 150px;
//     height: 400px;
//   }
// `;
// const StyledNavLink = styled(NavLink)`
//   text-decoration: none;
//   color: #ea7a20;
//   &:hover {
//     font-weight: bold;
//   }
// `;

// export default ProjectDetail;

import { useParams, NavLink } from "react-router-dom";
import styled from "styled-components";
import FadeInEffect from "./FadeInEffect";
import { projects } from "./data";
import ProjectCarousel from "./ProjectCarousel";

const ProjectDetail = () => {
  const { itemId } = useParams();
  const item = projects[itemId];

  const generateRows = () => {
    return item.imgUrl.map((imgUrl, index) => (
      <Row key={index} className={index % 2 === 0 ? "even-row" : "odd-row"}>
        {index % 2 === 0 ? (
          <>
            <ImageColumn>
              <ImageWrapper>
                <Image src={imgUrl} />
              </ImageWrapper>
            </ImageColumn>
            <TextColumn>
              {/* <Highlight>{item.description}</Highlight> */}
            </TextColumn>
          </>
        ) : (
          <>
            <TextColumn>
              {/* <Highlight>{item.description}</Highlight> */}
            </TextColumn>
            <ImageColumn>
              <ImageWrapper>
                <Image src={imgUrl} />
              </ImageWrapper>
            </ImageColumn>
          </>
        )}
      </Row>
    ));
  };

  return (
    <Main>
      <FadeInEffect>
        <h1 className="text-[30px] font-bold leading-[40px] py-[30px]">
          {item.name}
        </h1>
      </FadeInEffect>
      <FadeInEffect>
        <Description>{item.description}</Description>
      </FadeInEffect>
      <FadeInEffect>
        <table className="table-auto my-[30px] text-[22px]">
          <thead className="text-left">
            <tr>
              <th className="pb-[20px] lg:pr-[200px]">Stack</th>
              <th className="pb-[20px] lg:pr-[200px]">Code</th>
              {item.live ? (
                <th className="pb-[20px] lg:pr-[200px]">Live</th>
              ) : (
                <></>
              )}
            </tr>
          </thead>
          <tbody>
            {item.stack.map((element, index) => (
              <tr key={index}>
                <td>{element}</td>
                {index === 0 && (
                  <>
                    <td>
                      <StyledNavLink
                        to={{ pathname: item.code }}
                        target="_blank"
                        style={{ fontSize: "22px", paddingRight: "13px" }}
                      >
                        Repository
                      </StyledNavLink>
                    </td>
                    {item.live && (
                      <td>
                        <StyledNavLink
                          to={{ pathname: item.live }}
                          target="_blank"
                          style={{ fontSize: "22px" }}
                        >
                          Website
                        </StyledNavLink>
                      </td>
                    )}
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </FadeInEffect>
      <ContentGrid>{generateRows()}</ContentGrid>
      <ProjectCarousel currentProjectId={itemId} />
    </Main>
  );
};

const Main = styled.div`
  margin: 80px 100px 0px 100px;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 1024px) {
    margin: 80px 60px 0px 60px;
    padding: 0;
  }
`;

const ContentGrid = styled.div`
  display: grid;
  padding: 40px 0;
  grid-gap: 40px 0;

  .even-row {
    grid-template-columns: 2fr 1fr;
  }

  .odd-row {
    grid-template-columns: 1fr 2fr;
  }

  @media only screen and (max-width: 768px) {
    .even-row,
    .odd-row {
      grid-template-columns: 1fr !important;
      flex-direction: column-reverse;
    }
  }
`;

const Row = styled.div`
  display: grid;
  align-items: center;
  @media only screen and (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const ImageColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    order: 2;
  }
`;

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    order: 1;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 56%;
  height: 0;
  margin-bottom: 20px;
`;

const Image = styled.img`
  position: absolute;
  border-radius: 10px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  //   object-fit: scale-down;
  // &:hover {
  //   transform: scale(1.2);
  // }
  transition: transform 0.6s ease, filter 0.6s ease;
`;

const Highlight = styled.div`
  padding: 20px;
  margin: 0 40px;
  background-color: #f0f0f0;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  @media only screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const Description = styled.div`
  color: grey;
  font-size: 22px;
  line-height: 30px;
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (max-width: 440px) {
  }
`;

const StyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: #ea7a20;
  &:hover {
    font-weight: bold;
  }
`;

export default ProjectDetail;


// CIRCULAR THING

// import { useParams } from "react-router-dom";
// import styled from "styled-components";
// import { NavLink } from "react-router-dom";
// import FadeInEffect from "./FadeInEffect";
// import { projects } from "./data";
// import ProjectCarousel from "./ProjectCarousel";

// const ProjectDetail = () => {
//   const { itemId } = useParams();
//   const item = projects[itemId];

//   return (
//     <Main>
//       <FadeInEffect>
//         <h1 className="text-[30px] font-bold leading-[40px] py-[30px]">
//           {item.name}
//         </h1>
//       </FadeInEffect>
//       <FadeInEffect>
//         <Description>{item.description}</Description>
//       </FadeInEffect>
//       <FadeInEffect>
//         <table className="table-auto my-[30px] text-[22px] ">
//           <thead className="text-left">
//             <tr>
//               <th className="pb-[20px] lg:pr-[200px]">Stack</th>
//               <th className="pb-[20px] lg:pr-[200px]">Code</th>
//               {item.live ? (
//                 <th className="pb-[20px] lg:pr-[200px]">Live</th>
//               ) : (
//                 <></>
//               )}
//             </tr>
//           </thead>
//           <tbody>
//             {item.stack.map((element, index) => {
//               return (
//                 <tr key={Math.random()}>
//                   <td>{element}</td>
//                   {index === 0 ? (
//                     <>
//                       <td>
//                         <StyledNavLink
//                           to={{ pathname: item.code }}
//                           target="_blank"
//                           style={{ fontSize: "22px", paddingRight: "13px" }}
//                         >
//                           Repository
//                         </StyledNavLink>
//                       </td>
//                       {item.live ? (
//                         <td>
//                           <StyledNavLink
//                             to={{ pathname: item.live }}
//                             target="_blank"
//                             style={{ fontSize: "22px" }}
//                           >
//                             Website
//                           </StyledNavLink>
//                         </td>
//                       ) : (
//                         <></>
//                       )}
//                     </>
//                   ) : (
//                     <td />
//                   )}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </FadeInEffect>
//       <FadeInEffect>
//         <ImageCircle>
//           {item.imgUrl.map((element, index) => {
//             return (
//               <ImageWrapper key={index} index={index}>
//                 <Image src={element} />
//               </ImageWrapper>
//             );
//           })}
//         </ImageCircle>
//       </FadeInEffect>
//       <ProjectCarousel currentProjectId={itemId} />
//     </Main>
//   );
// };

// const Main = styled.div`
//   margin: 80px 100px 0px 100px;
//   padding: 0 50px;
//   display: flex;
//   flex-direction: column;
//   @media only screen and (max-width: 1024px) {
//     margin: 80px 60px 0px 60px;
//     padding: 0;
//   }
// `;

// const Description = styled.div`
//   color: grey;
//   font-size: 22px;
//   line-height: 30px;
//   @media only screen and (max-width: 768px) {
//   }
//   @media only screen and (max-width: 440px) {
//   }
// `;

// const StyledNavLink = styled(NavLink)`
//   text-decoration: none;
//   color: #ea7a20;
//   &:hover {
//     font-weight: bold;
//   }
// `;

// const ImageCircle = styled.div`
//   position: relative;
//   width: 400px;
//   height: 400px;
//   margin: 50px auto;
// `;

// const ImageWrapper = styled.div`
//   position: absolute;
//   width: 100px;
//   height: 100px;
//   border-radius: 50%;
//   overflow: hidden;
//   transition: transform 0.3s ease, z-index 0.3s ease;
//   top: 50%;
//   left: 50%;
//   transform: ${({ index }) =>
//     `rotate(${index * (360 / 5)}deg) translate(180px) rotate(-${index * (360 / 5)}deg)`};
//   &:hover {
//     transform: ${({ index }) =>
//       `rotate(${index * (360 / 5)}deg) translate(180px) rotate(-${index * (360 / 5)}deg) scale(1.5)`};
//     z-index: 10;
//   }
// `;

// const Image = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

// export default ProjectDetail;

// import { useParams } from "react-router-dom";
// import styled from "styled-components";
// import { NavLink } from "react-router-dom";
// import FadeInEffect from "./FadeInEffect";
// import { projects } from "./data";
// import ProjectCarousel from "./ProjectCarousel";

// const ProjectDetail = () => {
//   const { itemId } = useParams();

//   const item = projects[itemId];

//   return (
//     <Main>
//       <FadeInEffect>
//         <h1 className="text-[30px] font-bold leading-[40px] py-[30px]">
//           {item.name}
//         </h1>
//       </FadeInEffect>
//       <FadeInEffect>
//         <Description>{item.description}</Description>
//       </FadeInEffect>
//       <FadeInEffect>
//         <table className="table-auto my-[30px] text-[22px] ">
//           <thead className="text-left">
//             <tr>
//               <th className="pb-[20px] lg:pr-[200px]">Stack</th>
//               <th className="pb-[20px] lg:pr-[200px]">Code</th>
//               {item.live ? (
//                 <th className="pb-[20px] lg:pr-[200px]">Live</th>
//               ) : (
//                 <></>
//               )}
//             </tr>
//           </thead>
//           <tbody>
//             {item.stack.map((element, index) => {
//               return (
//                 <tr key={Math.random()}>
//                   <td>{element}</td>
//                   {index === 0 ? (
//                     <>
//                       <td>
//                         <StyledNavLink
//                           to={{ pathname: item.code }}
//                           target="_blank"
//                           style={{ fontSize: "22px", paddingRight: "13px" }}
//                         >
//                           Repository
//                         </StyledNavLink>
//                       </td>
//                       {item.live ? (
//                         <td>
//                           <StyledNavLink
//                             to={{ pathname: item.live }}
//                             target="_blank"
//                             style={{ fontSize: "22px" }}
//                           >
//                             Website
//                           </StyledNavLink>
//                         </td>
//                       ) : (
//                         <></>
//                       )}
//                     </>
//                   ) : (
//                     <td />
//                   )}
//                 </tr>
//               );
//             })}
//           </tbody>
//         </table>
//       </FadeInEffect>
//       <FadeInEffect>
//         <ImageContainer>
//           {item.imgUrl.map((element, index) => {
//             return (
//               <ImageWrapper key={index} index={index}>
//                 <Image src={element} />
//               </ImageWrapper>
//             );
//           })}
//         </ImageContainer>
//       </FadeInEffect>
//       <ProjectCarousel currentProjectId={itemId} />
//     </Main>
//   );
// };

// const Main = styled.div`
//   margin: 80px 100px 0px 100px;
//   padding: 0 50px;
//   display: flex;
//   flex-direction: column;
//   @media only screen and (max-width: 1024px) {
//     margin: 80px 60px 0px 60px;
//     padding: 0;
//   }
// `;

// const Description = styled.div`
//   color: grey;
//   font-size: 22px;
//   line-height: 30px;
//   @media only screen and (max-width: 768px) {
//   }
//   @media only screen and (max-width: 440px) {
//   }
// `;

// const StyledNavLink = styled(NavLink)`
//   text-decoration: none;
//   color: #ea7a20;
//   &:hover {
//     font-weight: bold;
//   }
// `;

// const ImageContainer = styled.div`
//   position: relative;
//   width: 800px;
//   height: 600px;
//   margin: 50px auto;
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: center;
//   align-items: center;
// `;

// const ImageWrapper = styled.div`
//   position: absolute;
//   width: 200px;
//   height: 200px;
//   transition: transform 0.3s ease, z-index 0.3s ease;
//   ${({ index }) => {
//     const positions = [
//       { top: "50px", left: "100px" },
//       { top: "50px", left: "300px" },
//       { top: "250px", left: "50px" },
//       { top: "250px", left: "350px" },
//       { top: "450px", left: "200px" },
//     ];
//     return positions[index];
//   }}
//   &:hover {
//     transform: scale(1.2);
//     z-index: 10;
//   }
// `;

// const Image = styled.img`
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
// `;

// export default ProjectDetail;
