import styled from "styled-components";
import { NavLink } from "react-router-dom";
import ButtonMailto from "./MailTo";
const Footer = () => {
  return (
    <Main>
      <Div>
        <Logo>
          <StyledNavLink exact to="/">
            MoB
          </StyledNavLink>
        </Logo>
        <H3 className="text-xs ">Designed and Built by Mohsen Bahaeddini</H3>
      </Div>
      <NavLinkDiv className="lg:pr-[125px]">
        <NavLinkDivGit
          to={{ pathname: "https://github.com/MohsenBahaeddini" }}
          target="_blank"
          style={{ fontSize: "14px", fontWeight: "bold", padding: "5px" }}
        >
          Github
        </NavLinkDivGit>
        <NavLinkDivLinkedin
          to={{ pathname: "https://www.linkedin.com/in/mohsen-bahaeddini/" }}
          target="_blank"
          style={{ fontSize: "14px", fontWeight: "bold", padding: "5px" }}
        >
          Linkedin
        </NavLinkDivLinkedin>
        <NavLinkDivEmail>
          <ButtonMailto label="Email" mailto="mailto:mbahaeddinii@gmail.com" />
        </NavLinkDivEmail>
      </NavLinkDiv>
    </Main>
  );
};

const Main = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding: 1rem;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
`;
const Div = styled.div`
  display: flex;
  align-items: center;
`;
const H3 = styled.h3`
  margin-left: 20px;
  color: grey;
  @media only screen and (max-width: 620px) {
    margin-left: 10px;
    font-size: 0.55rem;
  }
`;
const Logo = styled.h1`
  padding-left: 130px;
  font-size: 25px;
  @media only screen and (max-width: 1024px) {
    padding-left: 0;
  }
`;
const StyledNavLink = styled(NavLink)`
  color: black;
  font-weight: bold;
  font-size: 26px;
  font-family: "Kaushan Script", "Rubik Marker Hatch";
  text-decoration: none;
  &:hover {
    color: lightblue;
  }

  @media only screen and (max-width: 620px) {
    font-size: 22px;
  }
`;
const NavLinkDiv = styled.div`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: 620px) {
    /* margin-right: 20px; */
  }
  @media only screen and (max-width: 440px) {
    /* margin-right: -120px; */
  }
`;
const NavLinkDivGit = styled(NavLink)`
  &:hover {
    color: lightblue;
    cursor: pointer;
  }
`;
const NavLinkDivLinkedin = styled(NavLink)`
  &:hover {
    cursor: pointer;
    color: lightblue;
  }
`;
const NavLinkDivEmail = styled.div`
  display: flex;
  &:hover {
    color: lightblue;
  }
`;
export default Footer;
