import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import { NavLink } from "react-router-dom";
import FadeInEffect from "./FadeInEffect";

const CodeZone = ({ codeZone }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Main>
      <FullWidthWrapper>
        <SliderWrapper>
          <Slider {...settings}>
            {codeZone.map((item) => (
              <Item key={item.id}>
                <HoverEffectWrapper>
                  <ImageWrapper>
                    <Image src={item.coverImage} alt={item.name} />
                    <TextOverlay>
                      <H1>{item.name}</H1>
                      <H2>
                        {item.about} || {item.language}
                      </H2>
                      <NavLink to={`/code_zone/${item.id}`}>
                        <ViewH2>VIEW CODE {">"}</ViewH2>
                      </NavLink>
                    </TextOverlay>
                  </ImageWrapper>
                </HoverEffectWrapper>
              </Item>
            ))}
          </Slider>
        </SliderWrapper>
      </FullWidthWrapper>
    </Main>
  );
};

const Main = styled.div`
  padding: 0;
  background-color: black;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  width: 100vw;
`;

const FullWidthWrapper = styled.div`
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  padding: 0 100px;
   @media only screen and (max-width: 768px) {
    padding: 0 50px; /* Adjust padding for smaller screens */
  }
`;

const SliderWrapper = styled.div`
  .slick-slide {
    padding-right: 20px;
  }
`;

const HoverEffectWrapper = styled.div`
  position: relative;
  overflow: hidden;
  &:hover img {
    filter: blur(5px);
  }
  &:hover div {
    opacity: 1;
  }
`;

const ImageWrapper = styled.div`
  position: relative;
  padding-bottom: 82%;
  height: 0;
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  transition: filter 0.3s ease;
  @media only screen and (max-width: 1024px) {
    object-fit: inherit;
  }
`;

const TextOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
`;

const Item = styled.div`
  padding: 10px;
  text-align: center;
`;

const H1 = styled.h1`
  font-size: 24px;
  padding: 10px 0;

  font-weight: bold;
  color: white;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    padding-top: 0px;
  }
`;

const H2 = styled.h2`
  font-size: 22px;
  padding: 10px 0;
  color: white;
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    padding-top: 0px;
  }
`;

const ViewH2 = styled.h2`
  font-size: 20px;
  font-weight: bold;
  padding-top: 10px;
  color: white;
  &:hover {
    color: #ffc76c;
  }
  @media only screen and (max-width: 768px) {
    font-size: 16px;
    padding-top: 0px;
  }
`;

export default CodeZone;
