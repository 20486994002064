import styled from "styled-components";
import { NavLink } from "react-router-dom";

const Header = () => {
  return (
    <>
      <Main>
        <Logo>
          <StyledNavLink exact to="/">
            MoB
          </StyledNavLink>
        </Logo>
      </Main>
    </>
  );
};
const Main = styled.div`
  height: 100px;
`;
const Logo = styled.h1`
  padding: 50px 150px 100px;
  font-size: 25px;
  @media only screen and (max-width: 1024px) {
    padding-left: 60px;
  }
`;
const StyledNavLink = styled(NavLink)`
  color: black;
  font-weight: bold;
  font-size: 40px;
  font-family: "Kaushan Script", "Rubik Marker Hatch";
  text-decoration: none;
  &:hover {
    color: lightblue;
  }

  @media only screen and (max-width: 1024px) {
    font-size: 30px;
  }
  
`;
export default Header;
