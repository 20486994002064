import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { BsGithub, BsLinkedin, BsInstagram } from "react-icons/bs";
import { TbPoint } from "react-icons/tb";

import ProjectsList from "./ProjectsList";
import { projects, codeZone } from "./data";
import CodeZone from "./CodeZone";
import FadeInEffect from "./FadeInEffect";

import current from "./assets/current-project/current.jpg";
import partial from "./assets/current-project/partial.jpg";
import img4 from "./assets/current-project/img4.jpg";
import fullImage from "./assets/current-project/fullImage.jpg";
import barberapp from "./assets/current-project/barberapp.png";
import barber from "./assets/current-project/barber.png";
import barber2 from "./assets/current-project/barber2.png";


const Home = () => {
  return (
    <>
      <Main>
        <FadeInEffect>
          <Intro>
            <Line>
              <Div>
                <Hr />
              </Div>
              <SocialMediaDiv>
                <StyledNavLinkGit
                  to={{ pathname: "https://github.com/MohsenBahaeddini" }}
                  target="_blank"
                  style={{ fontSize: "20px", padding: "5px" }}
                >
                  <BsGithub />
                </StyledNavLinkGit>
                <StyledNavLinkLinkedin
                  to={{
                    pathname: "https://www.linkedin.com/in/mohsen-bahaeddini",
                  }}
                  target="_blank"
                  style={{ fontSize: "20px", padding: "5px" }}
                >
                  <BsLinkedin />
                </StyledNavLinkLinkedin>
              </SocialMediaDiv>
            </Line>
            <H1>
              Hi there, I'm Mohsen.
              <br />
              Software <span style={{ color: "lightblue" }}>Developer</span>,
              <br />
              with background in Engineering.
            </H1>
          </Intro>
        </FadeInEffect>
        <FadeInEffect>
          <About className="grid grid-cols-2 gap-3 flex">
            <p>
              A passionate full-stack developer who loves creating innovative
              and modern web applications.
            </p>
            <p>
              From Concordia's Full Stack Web Development Bootcamp to a Computer
              Science student, I bring a fusion of modern web development skills
              and innovative problem-solving. My work spans dynamic web
              applications crafted with JavaScript, HTML, CSS, and the MERN
              Stack, showcasing my journey and technical prowess.
            </p>
          </About>
        </FadeInEffect>
        <FadeInEffect>
          <Skills>
            <H2>My skills</H2>

            {/* <Skills1>
              I have an inquisitive mindset and a daring drive for learning new
              technologies, thanks to my engineering background. I devote a
              portion of my time every day to developing my skillset, and have
              gained a solid understanding of the web development concepts
              through my studies.
            </Skills1> */}
          </Skills>
        </FadeInEffect>
        <FadeInEffect>
          <SkillsGrid className="grid grid-cols-2 gap-2 py-[10px] px-[60px] text-[22px]  lg:grid-cols-4 gap-2">
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>JavaScript</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>React</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>Node & Express</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>Bootstrap & Tailwind</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>Java</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>Git & Github</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>MongoDB</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>HTML & CSS</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>PHP</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>AWS & Azure</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>SQL</Skill>
            </div>
            <div className="flex items-center">
              <TbPoint color="lightblue" size="22px" />
              <Skill>Styled-Components</Skill>
            </div>
          </SkillsGrid>
        </FadeInEffect>
        <FadeInEffect>
          <Projects>
            <H2>Projects</H2>
            <ProjectsList projectsList={Object.values(projects)} />
            {/* <NavLink exact to="/projects">
            project1
          </NavLink> */}
          <H2>Code Zone</H2>
          <CodeZone codeZone={Object.values(codeZone)} />
          </Projects>


        </FadeInEffect>
        <FadeInEffect>
          {/* <CurrentWorkDiv> */}
          <CurrentWrokImgDiv>
            {/* <CurrentWrokImage src={barberapp} /> */}
            <CurrentWrokImage src={barber2} alt="barbershop app" />

            {/* <CurrentWrokImg></CurrentWrokImg> */}
          </CurrentWrokImgDiv>
        </FadeInEffect>
        <FadeInEffect>
          <CurrentWorkDiv>
            <H2>GRIFFINSTYLE</H2>
            <CurrentWorkImgCaption>
              My current work in progress, a barbershop web app that helps
              clients <br />
              conveniently book appointments online.
              <br />
              <br />
              COMING SOON
            </CurrentWorkImgCaption>
          </CurrentWorkDiv>
        </FadeInEffect>
        {/* </CurrentWorkDiv> */}
      </Main>
    </>
  );
};
const CurrentWorkDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 20px 0 60px;
  text-align: center;
`;
const CurrentWorkImgCaption = styled.p`
  color: grey;
  font-size: 22px;
`;
const CurrentWrokImgDiv = styled.div`
  position: relative;
  padding-bottom: 48%;
  height: 0;
  display: flex;

  justify-content: center;

  /* margin: 100px 50px; */
  /* &:hover {
    padding-bottom: 136%;
  } */
`;

const CurrentWrokImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
`;
const CurrentWrokText = styled.div`
  color: grey;
  font-size: 22px;
  padding-right: 200px;
  padding-right: 140px;
  @media only screen and (max-width: 1024px) {
    padding-right: 140px;
    font-size: 22px;
  }
  @media only screen and (max-width: 768px) {
    padding-right: 60px;
  }
  @media only screen and (max-width: 440px) {
    /* padding-right: 0; */
    /* margin-right: -80px; */
  }
`;
// const CurrentWrokImg = styled.div`
//   background-image: url(${img4});

//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   object-fit: scale-down;
//   background-repeat: no-repeat;
//   background-color: red;
//   transition: background-image 0.5s ease, transform 0.5s ease;

//   &:hover {
//     background-image: url(${fullImage});
//   }
// `;

const Main = styled.div``;
const Intro = styled.div`
  margin: 80px 100px 0px 100px;
  @media only screen and (max-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
const Div = styled.div`
  padding: 10px;
`;
const SocialMediaDiv = styled.div`
  display: flex;
`;

const Skill = styled.h3`
  font-weight: bold;
`;
const SkillsGrid = styled.div`
  @media only screen and (min-width: 1024px) {
    padding: 10px 150px;
  }
  @media only screen and (max-width: 440px) {
    display: flex;
    flex-direction: column;
  }
`;
const Hr = styled.hr`
  width: 250px;
  height: 3px;
  background-color: grey;
  border: none;
  border-radius: 5px;
  @media only screen and (max-width: 640px) {
    width: 150px;
  }
`;
const Line = styled.div`
  margin-left: 40px;
  display: flex;
  justify-content: left;
  align-items: center;
  @media only screen and (max-width: 1024px) {
    margin-left: 50px;
  }
`;
const H1 = styled.h1`
  font-size: 45px;
  font-weight: bold;
  padding: 30px 100px;
  line-height: 60px;
  @media only screen and (max-width: 640px) {
    font-size: 32px;
    padding-right: 40px;
    line-height: 40px;
  }
  @media only screen and (max-width: 450px) {
    font-size: 28px;
    /* padding-right: 0; */
  }
`;

const About = styled.div`
  color: grey;
  font-size: 22px;
  padding: 50px 150px;
  @media only screen and (max-width: 1024px) {
    padding: 50px 0px 50px 60px;
    font-size: 22px;
    padding-right: 60px;
    gap: 2rem;
  }
  @media only screen and (max-width: 540px) {
    display: flex;
    flex-direction: column;
  }
  @media only screen and (max-width: 440px) {
    /* margin-right: -120px; */
  }
`;
const Skills = styled.div`
  padding: 10px 150px;

  @media only screen and (max-width: 1024px) {
    padding: 10px 60px;
    padding-right: 0;
  }
  @media only screen and (max-width: 440px) {
    /* padding: 10px 60px;
    padding-right: 0; */
  }
`;
const H2 = styled.h2`
  font-size: 30px;
  color: black;
  padding: 20px 0px;
  font-weight: bold;
  @media only screen and (max-width: 440px) {
    font-size: 28px;
  }
`;
const Skills1 = styled.div`
  color: grey;
  font-size: 22px;
  padding-right: 200px;
  padding-right: 140px;
  @media only screen and (max-width: 1024px) {
    padding-right: 140px;
    font-size: 22px;
  }
  @media only screen and (max-width: 768px) {
    padding-right: 60px;
  }
  @media only screen and (max-width: 440px) {
    /* padding-right: 0; */
    /* margin-right: -80px; */
  }
`;
const Skills2 = styled.div`
  @media only screen and (max-width: 640px) {
  }
`;
const Projects = styled.div`
  color: grey;
  padding: 50px 150px 100px;
  @media only screen and (max-width: 1024px) {
    padding-left: 60px;
    padding-right: 60px;
  }
  @media only screen and (max-width: 440px) {
    padding-left: 60px;
    /* margin-right: -120px; */
  }
`;
const StyledNavLinkLinkedin = styled(NavLink)`
  color: grey;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    color: #0072b1;
  }
`;
const StyledNavLinkGit = styled(NavLink)`
  color: grey;
  font-weight: bold;
  text-decoration: none;
  &:hover {
    color: #171515;
  }
`;
export default Home;
