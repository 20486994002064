import { useState, useEffect, useRef } from "react";
import "./index.css";

const FadeInEffect = (props) => {
  const [isVisible, setIsVisible] = useState(false);
  const [hasFadeIn, setHasFadeIn] = useState(false);
  const domRef = useRef();

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasFadeIn) {
          setIsVisible(true);
          setHasFadeIn(true);
        }
      });
    });
    if (domRef.current) {
      observer.observe(domRef.current);
    }

    return () => {
      if (domRef.current) {
        observer.unobserve(domRef.current);
      }
    };
  }, [hasFadeIn]);

  return (
    <div
      className={`fade-in-section ${isVisible ? "is-visible" : ""}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
};

export default FadeInEffect;
