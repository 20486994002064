import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "styled-components";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import Home from "./Home";
import Header from "./Header";
import Footer from "./Footer";
import ProjectDetail from "./ProjectDetail";
import GlobalStyles from "./GlobalStyles";
import "./index.css";
import ScrollToTop from "./ScrollToTop";
import CodeDetail from "./CodeDetail";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <GlobalStyles />
        <Main>
          <Header />
          <Switch>
            {/* <Route exact path="/">
              <Home />
            </Route>
            <Route exact path="/projects/:itemId">
              <ProjectDetail />
            </Route>
            <Route exact path="/code_zone/:itemId">
              <CodeDetail />
            </Route> */}
            <Route exact path="/" component={Home} />
            <Route exact path="/projects/:itemId" component={ProjectDetail} />
            <Route exact path="/code_zone/:itemId" component={CodeDetail} />
          </Switch>
          <Footer />
        </Main>
      </ScrollToTop>
    </BrowserRouter>
  );
};
const Main = styled.div`
  display: flex;
  margin: auto;
  flex-direction: column;
  max-width: 1600px;
`;
export default App;
